import { observable, action } from "mobx";
import ImageCropperService from '../services/ImageCropperService';
import MyProfileService from '../services/MyProfileService';
import UtilService from "../services/UtilsService";
import UIStore from "./UIStore";
class MyProfileStore {
   uiStore: any;
   constructor(){
     this.uiStore = UIStore;

   }
  @observable 
    descricao: string = "";
  
  @observable 
    myEventos: any[] = [];  
 
  @action setDescricao = (descricao: string) => {
    this.descricao= descricao;
  }
  @observable 
  name: string = "";
  @action setName = (name: string) => {
    this.name= name;
  }
  @observable 
  celular: string = "";
  @action setCelular = (celular: string) => {
    this.celular= celular;
  }
  @observable 
  genero: string = "";

  @action setGenero = (genero: string) => {
    this.genero= genero;
  }
  @observable 
    preferencia: any = {
      male: false,
      female: false,
      other: false
    };
 
  @action setPreferencia = (preferenciaCheck: any) => {
    this.preferencia[preferenciaCheck.gender] =preferenciaCheck.value;
    // this.descricao= descricao;
  }
  @observable 
    selectedImage: any;
 
  @action setSelectedImage = (image: any) => {
    this.selectedImage= image;
  }
  @action saveCroppedImage = async (image: any, crop:any) => {
    const image_url = await ImageCropperService.getCroppedImg(image,crop,0);
    this.images =[image_url.imageUrl];
  }
  @observable 
    images: string[]=[];
 
  @action changeImagePosition = (index: number, newIndex: number) => {
    this.images.splice(newIndex, 0, this.images.splice(index, 1)[0]);
  }
  @action removeImageAtPosition = (index: number) => {
    this.images.splice(index, 1);
  }
  @action saveProfile =() => {
    UIStore.setLoading(true);
    const profileJson ={
      description:'',
      pictures:this.images,
      gender:this.genero,
      preference_male:this.preferencia.male,
      preference_female:this.preferencia.female,
      preference_other:this.preferencia.other,
      celular:this.celular,
      name: this.name
    }
    MyProfileService.create(profileJson);
    UIStore.setLoading(false);
    this.uiStore.setAlertMessage('Perfil salvo com sucesso!')

  }
  @action loadFromAuth(profile:any){
    UtilService.log('Load from Auth');
    if(profile){
    this.name = profile.name;
    if(!profile.pictures){
      this.images =[];
    }else{
      this.images = profile.pictures;
    }
    this.genero = profile.gender;
    this.celular = profile.celular;
    this.descricao = profile.description;
    this.preferencia.male =profile.preference_male;
    this.preferencia.female =profile.preference_female;
    this.preferencia.other =profile.preference_other;
    this.myEventos = profile.eventos;
  }
  }
  @action loadFromMe(profile:any){
    UtilService.log('Load from Me');
    if(profile){
    this.name = profile.name;
    if(!profile.pictures){
      this.images =[];
    }else{
      this.images = profile.pictures;
    }
    this.genero = profile.gender;
    this.descricao = profile.description;
    this.preferencia.male =profile.preference_male;
    this.preferencia.female =profile.preference_female;
    this.preferencia.other =profile.preference_other;
    this.celular = profile.celular;
  }
  }
}

// export default createContext(new MyProfileStore())
export default new MyProfileStore();