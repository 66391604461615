import axios from "axios";
import { useQuery } from 'react-query'
import UtilService from "../services/UtilsService";


    const getEventos = async() =>{
        const {data} = await axios.get(process.env.REACT_APP_ENDPOINT+'/myeventos',{headers:{UserMetrics:window.localStorage.getItem("UserMetrics")},withCredentials: true});     
        let ids = data.map((o: { id: any; }) => o.id)
        let filtered = data.filter(({id}: any, index: number) => !ids.includes(id, index+1))
        UtilService.log('Eventos', filtered);
        return filtered;
        
    }
    export default function useEventos() {
        return useQuery('myEventos', getEventos);
    }
