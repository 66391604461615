import React from "react";
import NumberFormat from "react-number-format";

interface CelMaskProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }
  
export function CelMask(props: CelMaskProps) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
              // (21)99999-9999
        format={'(##)#####-####'}
        isNumericString
      />
    );
  }