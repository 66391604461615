import React, {createContext, useContext} from 'react';
import MessageInput from './messageinput/MessageInput';
import MessagesList from './messageList/MessageList';
import chat_tile from '../../static/chat_tile.png'; // with import
import Navbar from './navbar/navbar';
import ChatStore from '../../store/ChatStore';
import { observer } from 'mobx-react';
import { CircularProgress, Grid } from '@material-ui/core';

    

export const Message: React.FC = observer(() => {
    const chatStore = useContext(createContext(ChatStore));

const getMessages = () =>{
  if(chatStore.chats[chatStore.chatInfo.chatId]){
    return chatStore.chats[chatStore.chatInfo.chatId].toJS();
  }else{
    return [];
  }
}
 


return(
    // <div className={classes.container}>

    
<Grid
  direction="column"
  justify="space-between"
  alignItems="center"
  spacing={0}>

    <Navbar chat={{name:chatStore.chatInfo.name, picture:chatStore.chatInfo.userImage}} />
      {/* <ChatNavbar chat={chat} history={history} /> */}
      {/* {chat.messages && <MessagesList messages={chat.messages} />} */}
      {/* {JSON.stringify(chatStore.chats_archive['admin@chat.ficada.com.br'])} */}
      { chatStore.status === 'ON' &&
     <>
     <div style={{backgroundImage:`url(${chat_tile})`, height:'calc(100vh - 156px)', width:'100%',overflow:'auto'}}>
        <MessagesList messages={getMessages()} />
      </div>
      <MessageInput/>
      </>
    }
      {chatStore.status === 'OFF' && <CircularProgress></CircularProgress>}  
       </Grid>
    // </div>

)})