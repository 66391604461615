import { observable, action } from "mobx";

class UIStore {

  @observable 
  drawer: boolean = false;
  @observable 
  loading: boolean = false;

  @observable 
  errorMsg = '';


  @observable 
  alertOpen: boolean = false;
  @observable 
  alertMessage: string = '';
  @observable 
  alertColor: string = '';
  @observable 
  alertProps: any = {};

  @action setErrorMsg(msg: string){
    this.errorMsg = msg;
  }

  @action setAlertOpen = (value: boolean) => {
    this.alertOpen= value;
    if(value === true){
      setTimeout(()=> {
        this.alertOpen= false;
    }, 3000);
  }
}
@action setAlertMessage = (value: string) => {
  this.alertMessage= value;
}
@action setLoading = (value: boolean) => {
  this.loading= value;
}
@action setAlertColor = (value: string) => {
  this.alertMessage= value;
}
@action setAlertProps = (value: any) => {
  this.alertProps= value;
}
@action toggleDrawer = () => {
  this.drawer= !this.drawer;
}
  
}

export default new UIStore();