/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, LinearProgress, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';
import CheckinService from '../../../services/CheckinService';
import UtilService from '../../../services/UtilsService';
import { GamecardComponent } from '../gamecard/gamecardComponent';
import './gamelist.css';


interface GameListInterface{
  profile: any;
}
export const GameListComponent: React.FC<GameListInterface>= (props: GameListInterface) => {
  const query =new URLSearchParams(useLocation().search);
  const idEvento = query.get("hash");
  const [offset, setOffset] = useState(0);
  const [profiles, setProfiles] = useState<any[]>([]);
  const [hasMore, setHasmore] = useState(true);
  const mql = window.matchMedia('(max-width: 600px)');
    let mobileView = mql.matches;
    
  
  let preference = {
    female:props.profile.preference_female,
    male:props.profile.preference_male,
    other:props.profile.preference_other}

  function shouldLoadMore(tt: number, off: number){
    UtilService.log(`TOTAL: ${tt} OFFSET:${off}`)
    if(tt>off){
      return true;
    }else{
      return false;
      //TODO retry after a while
    }
  }  
  function loadProfiles(){
    UtilService.log(`Abrindo mais profiles do evento ${idEvento} com offset de ${offset}`);
    CheckinService.getProfilesInGame(idEvento,offset, props.profile.gender,preference).then(newProfiles =>{
      let profiles_load =[...profiles,...newProfiles.profiles];
      let total_load : number= +newProfiles.total;
      setProfiles(profiles_load);
      let novo_off = offset+ newProfiles.profiles.length;
      setOffset(novo_off);
      // let now = new Date();
      // let newDateObj = new Date(now.getTime() + 5*60000);
      // setExpTotal(newDateObj);
      let hasmore_load =shouldLoadMore(total_load, novo_off);
      setHasmore(hasmore_load);
    })
  }
  useEffect(() => {
    loadProfiles()
    UtilService.log('Onload',profiles);
  },[]);

  return(

    <>
    { mobileView && 
    <InfiniteScroll
    dataLength={profiles.length} //This is important field to render the next data
    next={loadProfiles}
    hasMore={hasMore}
    loader={<LinearProgress color="primary" />}
    endMessage={
    <>
    <p style={{ textAlign: 'center' }}>
        <b>Parece que você já viu tudo por agora</b>
      </p>
      <p>
       <Button variant="contained" color="primary" onClick={loadProfiles}  > Recarregar </Button> 
       </p>
    </>
}
  >
    <Grid container spacing={0} justify="space-evenly" >
{profiles.map((x, i) =>
<GamecardComponent key={i} profile={profiles[i]} eventId={idEvento}  />
)}
  </Grid>
</InfiniteScroll>
}
 {!mobileView &&
<h1>Infelizmente esse site só pode ser vizualizado no seu smartphone </h1>
}
</>
  )
};