import React, { createContext, useContext, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MessageItem } from './MessageItem';
import { observer } from 'mobx-react';
import ChatStore from '../../../store/ChatStore';


interface Message {
  id: string | null;
  content: string | null;
  createdAt: string | null;
  isMine: boolean
}
interface MessagesListProps {
  messages: Array<Message>;
}

const MessagesList: React.FC<MessagesListProps> = observer(({ messages }) => {
  const chatStore = useContext(createContext(ChatStore));
  const messageEl = useRef<HTMLDivElement|null>(null);
  const useStyles = makeStyles({
    container :{ 
        overflowY: "scroll", //changed
        padding: "0 15px",
    }
    });

    const classes = useStyles();

    useEffect(() => {
      const getChatHistory =async ()=>{chatStore.getHistory()};
      if (messageEl) {
        messageEl.current?.addEventListener('DOMNodeInserted', (event: any) => {
          const { currentTarget: target } = event;
          target?.scroll({ top: target?.scrollHeight, behavior: 'smooth' });
        });
      }
      getChatHistory();
    }, [chatStore])


   return (
    <div className={classes.container} ref={messageEl} >
      {messages.map((message: any) => (
        <MessageItem 
          data-testid="message-item" 
          id={message.id}
          key={message.id}
          content ={message.content}
          createdAt= {message.createdAt}
          isMine= {message.isMine}
          >
        </MessageItem>
      ))}
    </div>
  );
});

export default MessagesList;