import { observer } from "mobx-react"
import React from "react"
import { BrowserRouter, Switch, Route } from 'react-router-dom';
//Import Pages
import {Message} from '../pages/Message/Message';
import {PhotoDetail} from '../pages/PhotoDetail/PhotoDetail';
import {Chat} from '../pages/Chat/Chat';
import {Confirm} from '../pages/Confirm/Confirm';
import {Checkin} from '../pages/Checkin/Checkin';
import {Game} from '../pages/Game/Game';
import {ProfileImageCropper} from '../pages/MyProfile/ProfileImageCropper/ProfileImageCropper';
import {MyProfile} from '../pages/MyProfile/MyProfile';
import {Home} from '../pages/Home/Home';
import { ErrorPage } from "../pages/ErrorPage/ErrorPage"



const BaseRouter = observer(() => {

    return (
 <BrowserRouter>
 <Switch>
           <Route path="/message" exact={true} component={Message} />
          <Route path="/" exact={true} component={Home} />
           <Route path="/photodetail" exact={true} component={PhotoDetail} />
           <Route path="/chat" exact={true} component={Chat} />
           <Route path="/confirm/:otp" exact={true} component={Confirm} />
           <Route path="/checkin" exact={true} component={Checkin} />
           <Route path="/game" exact={true} component={Game} />
           <Route path="/profileimagecropper" exact={true} component={ProfileImageCropper} />
           <Route path="/myprofile" exact={true} component={MyProfile} />
           <Route path="/home" exact={true} component={Home} />
           <Route path="/error" exact={true} component={ErrorPage} />
     {/* <Route path="/" exact={true}  /> */}
     {/* <AuthenticatedRoute
     path="/"
     component= {Oportunidades}
     failComponent= {Anuncio}
     accepted ={(authStore.user?.login?.fornecedor)}
   /> */}
 </Switch>
</ BrowserRouter>
    )
});
export default BaseRouter; 