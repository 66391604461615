import React, { useContext, createContext } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Avatar, Grid } from '@material-ui/core';
import logo from '../../static/ficadalogo.png'; // with import
import DrawerComponent from './Drawer';
import { observer } from 'mobx-react';
import UIStore from '../../store/UIStore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { pink } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom';
import useMe from '../../hooks/useMe';


const drawerWidth = 240;


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap:'wrap',
      maxWidth:'100vw'
        },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    // appBarShift: {
    //   width: `calc(100% - ${drawerWidth}px)`,
    //   marginLeft: drawerWidth,
    //   transition: theme.transitions.create(['margin', 'width'], {
    //     easing: theme.transitions.easing.easeOut,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    // },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    pink: {
      color: theme.palette.getContrastText(pink[500]),
      backgroundColor: pink[500],
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    smallIcon: {
      fontSize: "0.8em"
    },
  }),
);

const Header = observer((props: { children: React.ReactNode; }) => {
  const classes = useStyles();
  const uiStore = useContext(createContext(UIStore));    
  const history = useHistory();
  const me = useMe();
//   useEffect(() => {
//     authStore.loadMe();
// }, []);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classes.appBar}
        style={{ background: '#98c8da' }}
      >
        <Toolbar>
        <Grid item>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={()=>uiStore.toggleDrawer()}
            edge="start"
            className={clsx(classes.menuButton, uiStore.drawer && classes.hide)}
            data-uandu='open-menu-button'
          >
            <MenuIcon />
          </IconButton>
          </Grid>
          <Grid item>
            <img src={logo}  height="32" alt=''></img>
          </Grid>
          <Grid item xs />
          {me.data?.id &&
          <Grid item>
            <IconButton onClick={()=>{history.push('/chat')}}>
              <Avatar className={classes.pink} >
                <FavoriteIcon  className={classes.smallIcon}/>
              </Avatar>
            </IconButton>
          </Grid>
          }
         
            
        </Toolbar>
      </AppBar>
     <DrawerComponent></DrawerComponent>
     {!uiStore.drawer && 
      <main className={classes.content} >
        <div className={classes.drawerHeader} />
        {props.children}
      </main>
      }
    </div>
  
  );
})
export default Header; 