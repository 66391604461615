import { Avatar, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Paper } from '@material-ui/core';
import React, { createContext, useContext} from 'react';
import './meuseventos.css';
import { useHistory } from 'react-router-dom';
import UIStore from '../../../store/UIStore';
import { observer } from 'mobx-react';
import useEventos from '../../../hooks/useEventos';
import Countdown from 'react-countdown';



export const MeusEventos: React.FC= observer(() => {
  const uiStore = useContext(createContext(UIStore));    
  const history = useHistory();
  const eventos = useEventos();

  function goTo(path : string){
    history.push(path);
    uiStore.toggleDrawer();
 }

  return(
<Paper style={{maxHeight: 200, overflow: 'auto'}}>
    { eventos.status === 'success' &&
    <List 
    subheader={
      <ListSubheader component="div" id="nested-list-subheader">
        Meus Eventos
      </ListSubheader>
    }>
      {eventos.data.map((evento: any) => 
         <ListItem button  onClick={(event) => goTo(`/game?hash=${evento.id}`)}  key={evento.id} >
        <ListItemAvatar>
           <Avatar alt="Remy Sharp" src={evento.imagem} />
         </ListItemAvatar>
             <ListItemText primary={evento.titulo} secondary={<>Evento termina em: <b><Countdown date={evento.endDate} /></b></>}/>
       </ListItem>
      )}

    </List>
    }
  </Paper>
  )
});