import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Box from '@material-ui/core/Box';



interface ChatNavbarProps {
  chat?: {
    picture?: string | null;
    name?: string | null;
  };
}

const Navbar: React.FC<ChatNavbarProps> = ({ chat}) => {
    const history = useHistory();
    const useStyles = makeStyles({

    root: {
        display: 'flex',
        flexDirection:'column'
          },
         menuButton: {
        marginRight: '2',
      },
    container :{ 
        padding: 0,
        display: 'flex',
       flexdirection: 'row',
       backgroundcolor: 'var(--primary-bg)',
    },
    picture:{
        height: "40px",
        width: "40px",
        marginTop: "3px",
        marginLeft: "-22px",
        objectFit: "cover",
        padding: "5px",
        borderRadius: "50%"
    },
    name:{
        lineHeight: "56px"
    }
    });

    const classes = useStyles();



  return (
    <div className={classes.root}>

    <AppBar
    position="sticky"
    style={{ background: 'black' }}
  >
    <Toolbar className={classes.container} >
        <Box display='flex' flexDirection="row" pl={1}>
    <Box mr={2}>

    <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={()=>{history.goBack()}}
            edge="start"
            data-uandu='open-menu-button'
          >
            <ArrowBackIosIcon />
          </IconButton>
          </Box>
      {chat && chat.picture && chat.name && (
        <React.Fragment>
          <img className={classes.picture} data-testid="chat-picture" src={chat.picture} alt=""/>
          <div className={classes.name} data-testid="chat-name">{chat.name}</div>
        </React.Fragment>
      )}
      </Box>
    </Toolbar>
    </AppBar>
    </div>
  );
};

export default Navbar;