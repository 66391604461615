import axios from 'axios';
import UtilService from './UtilsService';

const MyProfileService = {
  async create(myprofile: any): Promise<any> {
    try {
      const response = await axios.post(process.env.REACT_APP_ENDPOINT+'/profile', myprofile,{headers:{UserMetrics:window.localStorage.getItem("UserMetrics")},withCredentials: true});
      return response.data;
  } catch(error) {
      return error.response;
  }
},
async uploadImage(image: string): Promise<any> {
  try {
    var newDataUri = await this.resizedataURL(image,300,533);
    UtilService.log(newDataUri.slice(0,20));

    const response = await axios.post(process.env.REACT_APP_ENDPOINT+'/imageprofile', {image:newDataUri}, {headers:{UserMetrics:window.localStorage.getItem("UserMetrics")},withCredentials: true});
    return response.data;
} catch(error) {
    return error.response;
}
},
resizedataURL(datas: any, wantedWidth: any, wantedHeight: any):Promise<any>{
  return new Promise(async (resolve,reject)=>{

      // We create an image to receive the Data URI
      var img = document.createElement('img');
      let imgDimensions = await this.getImageWidth(datas);
      // When the event "onload" is triggered we can resize the image.
      img.onload =()=>{        
          // We create a canvas and get its context.
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');
       
          if(imgDimensions.width < wantedWidth){
            canvas.width = imgDimensions.width;
            canvas.height = imgDimensions.width;
        }else{
          canvas.width = wantedWidth;
          canvas.height = wantedHeight;
        }
          // We resize the image with the canvas method drawImage();
          ctx?.drawImage(img, 0, 0, wantedWidth, wantedHeight);

          const base64Img =canvas.toDataURL('image/jpeg', 1.0);

          // This is the return of the Promise
          resolve(base64Img);
      };

      // We put the Data URI in the image's src attribute
      img.src = datas;

  })
},
getImageWidth(imageData:any ): Promise<any>{
  return new Promise(async function(resolve,reject){
    let i = new Image(); 
  i.onload = function(){
    resolve({width:i.width, height:i.height});
  };
  i.src = imageData; 
})
}
};

export default MyProfileService;