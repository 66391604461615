import React from 'react';
import './likebtn.css';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { Chip } from '@material-ui/core';
import { useMutation } from 'react-query';
import axios from 'axios';
import useStickyState from '../../hooks/useStickyState';
import UtilService from '../../services/UtilsService';

interface LikeProps{
  targetId?: any
  eventId?: any
}

export const LikebtnComponent: React.FC<LikeProps>= (props : LikeProps) => {

  // const [active, setActive] = useLikePersistence(false);

  const [active, setActive] = useStickyState(false, `${props.eventId}/${props.targetId}` );


const addLikeMutation = useMutation(
  newLike => axios.post(`${process.env.REACT_APP_ENDPOINT}/like/${props.eventId}/${props.targetId}`,{},{headers:{UserMetrics:window.localStorage.getItem("UserMetrics")},withCredentials:true}),
  {
    retry:5,
    onError: (err, variables, context) => {
      UtilService.log(err);
    },
    onSuccess:()=>{
      setActive(true);
    }
  }
)


  return(
    <React.Fragment>
    {process.env.NODE_ENV==='development' && 
      <p>{props.eventId+"_"+props.targetId}</p>
    }
    {!active && 
    <div 
    className='buttonLove' 
    onClick={ (e) => {e.preventDefault();
      addLikeMutation.mutate();
   }}>

    <FavoriteIcon  style={{ fontSize: 40 }}/>
  </div>
  }
   {active && 
      <Chip
      icon={<FavoriteIcon />}
      label="Você já curtiu"
    />
   }
  </React.Fragment>
  )
};