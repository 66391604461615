import React, { useContext, createContext } from 'react';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import UIStore from '../../store/UIStore';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import InfoIcon from '@material-ui/icons/Info';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import HomeIcon from '@material-ui/icons/Home';
import {  MeusEventos } from './meuseventos/meuseventosComponent';
import useMe from '../../hooks/useMe';
import AuthService from '../../services/AuthService';
import UtilService from '../../services/UtilsService';
const drawerWidth = '100vw';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    bottomPush:{
      position: "fixed",
      bottom: 0,
      textAlign: "center",
      paddingBottom: 10,
      width: drawerWidth
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
);



const DrawerComponent = observer(() => {

    const classes = useStyles();
    const theme = useTheme();
    const uiStore = useContext(createContext(UIStore));    
    const history = useHistory();
    const me = useMe();
  
  function goTo(path : string){
    history.push(path);
    uiStore.toggleDrawer();
  }
  async function  logout(){
    UtilService.log('Logout');
    await AuthService.logout();
    await me.refetch();
    history.push("/home");
  }
    return (
     
      <Drawer
      className={classes.drawer}
      anchor="left"
      variant ='persistent'
      transitionDuration ={500}
      open={uiStore.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
     
    >

      <div className={classes.drawerHeader}  >
        <IconButton onClick={()=>uiStore.toggleDrawer()} data-uandu='close-menu-button'>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      {!uiStore.loading &&
      <React.Fragment>
      <List>
           {me.data?.id &&
            <ListItem button  onClick={(event) => goTo('/home')}  key={'Home'} >
              <ListItemIcon><HomeIcon/></ListItemIcon>
              <ListItemText primary='Home' />
            </ListItem>
          }
          {!me.data?.id &&
            <ListItem button  onClick={(event) => goTo('/home')}  key={'Home'} >
              <ListItemIcon><LockOpenIcon/></ListItemIcon>
              <ListItemText primary='Realizar Login' />
            </ListItem>
          }

        <ListItem button  onClick={(event) => goTo('/faq')}  key={'FAQ'} >
            <ListItemIcon><InfoIcon/></ListItemIcon>
            <ListItemText primary='Como Funciona?' />
      </ListItem>
      {me.data?.id &&
      <ListItem button  onClick={(event) => goTo('/myProfile')}  key={'MyProfile'} >
            <ListItemIcon><AccountBoxIcon/></ListItemIcon>
            <ListItemText primary='Meu Perfil' />
      </ListItem>
      }
      </List>
      {me.data?.id &&
      <MeusEventos></MeusEventos>
      }
     </React.Fragment>
}
    <div className={classes.bottomPush}>
        {me.data?.id && 
      <Button variant="contained" color="primary" fullWidth={true}	onClick={()=>{logout()}} disableElevation>
          Sair
      </Button>
      }
    </div>
    </Drawer>
      );
    })
  
    export default DrawerComponent; 