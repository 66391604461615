import axios from "axios";
import { useQuery } from 'react-query'
import UtilService from "../services/UtilsService";


    const getFicadas = async() =>{
        const {data} = await axios.get(`${process.env.REACT_APP_ENDPOINT}/ficada`,{headers:{UserMetrics:window.localStorage.getItem("UserMetrics")},withCredentials: true});
        UtilService.log('ficadas',data);
        return data;    

    }
    export default function useFicadas() {
        return useQuery('ficadas', getFicadas);
    }
