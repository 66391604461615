import React, { useContext, useEffect, useState } from 'react'
import {  Box, Paper, Container} from '@material-ui/core';
import BackHeader from '../../layout/header/BackHeader';
import { observer } from 'mobx-react';
import FicadaStore from '../../store/FicadaStore';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const PhotoDetail: React.FC = observer(() => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const ficadaStore = useContext(FicadaStore);


    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
return(
<BackHeader>
  <Container>
    <Box  component={Paper} height={windowDimensions.height-100}  mt={3}>
      <div style={{ backgroundImage: `url(${ficadaStore.detailImage})`, backgroundSize:'cover',width:'100%', height:'100%'}}></div>
    </Box>
    </Container>
</BackHeader>
)})