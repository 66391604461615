import React, { createContext, useCallback, useContext, useState } from 'react'
import Container from '@material-ui/core/Container';
import Header from '../../../layout/header/Header';
import MyProfileStore from '../../../store/MyProfileStore';
import Cropper from 'react-easy-crop'
import { Box, Button } from '@material-ui/core';
import { useCropperStyles } from './styles';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { useHistory } from 'react-router-dom';
import UIStore from '../../../store/UIStore';
import UtilService from '../../../services/UtilsService';


export const ProfileImageCropper: React.FC = () => {
const store = useContext(createContext(MyProfileStore));
const uiStore = useContext(createContext(UIStore));
const history = useHistory();
const [crop, setCrop] = useState({ x: 0, y: 0 })
const [zoom, setZoom] = useState(1)
const classes = useCropperStyles();
const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null)

const showCroppedImage = useCallback(async () => {
    try {
      uiStore.setLoading(true);
       await store.saveCroppedImage(
        store.selectedImage,
        croppedAreaPixels
      )
      uiStore.setLoading(false);
      await store.saveProfile();
      history.push('/myprofile');
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, history, store, uiStore])

const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
  UtilService.log('Cropped');
    setCroppedAreaPixels(croppedAreaPixels)
}, [])
return(
    <Header>
        <Container component="main" maxWidth="xs" >
        <Box display='flex' flexDirection='column'>
            <Box>
        <div className={classes.cropcontainer}>
            <Cropper
                image={store.selectedImage}
                crop={crop}
                zoom={zoom}
                aspect={9/ 16}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                />
        </div>
        </Box>
        <div className={classes.controls}>
            <Button variant="contained" 
                    color="primary" 
                    component="span" 
                    startIcon={<PhotoCameraIcon></PhotoCameraIcon>}
                    onClick={showCroppedImage}
                    >
                Adicionar imagem
            </Button>
        </div>
        </Box>
</Container>
</Header>

)}