import React from 'react';
import { FacebookProvider, LoginButton } from 'react-facebook';
import { Button } from '@material-ui/core';
import AuthService from '../../../services/AuthService';
import { observer } from 'mobx-react';
import FacebookIcon from '@material-ui/icons/Facebook';
import { facebookloginbuttonStyles } from './facebookloginbuttonStyles';
import useMe from '../../../hooks/useMe';
import useEventos from '../../../hooks/useEventos';
import UtilService from '../../../services/UtilsService';


export const FacebookLoginButtonComponent: React.FC= observer(() => {
const styles = facebookloginbuttonStyles();
const me = useMe();
const eventos = useEventos();
async function handleResponse(response: any){
  const fbresponse = await AuthService.facebookLogin(response);
  UtilService.log(fbresponse);
  me.refetch();
  eventos.refetch();
  UtilService.log(me.data);
}
function handleError(response: any){
    UtilService.log(response);
    }
  return(
    <FacebookProvider appId={process.env.REACT_APP_FBID}  autoLoad>
      {!me.data?.id && 
            <LoginButton
              scope={["email"]}
              onCompleted={handleResponse}
              onError={handleError}
              className={styles.button}>
              <Button  id='fbLoginBtn' name='fbLoginBtn' className={styles.facebook} fullWidth startIcon={ <FacebookIcon />}>
                Entrar com Facebook
              </Button>
              </LoginButton>
          }
          </FacebookProvider>
  )
});