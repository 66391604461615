import React, { createContext, useContext } from 'react'
import Container from '@material-ui/core/Container';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid,  Radio, RadioGroup, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import MyProfileStore from '../../store/MyProfileStore';
import Header from '../../layout/header/Header';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import { SingleImageSelectorComponent } from './singleimageselector/singleimageselectorComponent';
import UIStore from '../../store/UIStore';
import { GeneralAlert } from '../../layout/alert/alertComponent';
import { Alert } from '@material-ui/lab';
import { useHistory, useLocation } from 'react-router-dom';
import { CelMask } from '../../layout/masks/CelMask';


export const MyProfile: React.FC = observer(() => {
const store = useContext(createContext(MyProfileStore));
const uiStore = useContext(createContext(UIStore));
const query =new URLSearchParams(useLocation().search);
const history = useHistory();
const idEvento = query.get("hash");

const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    store.setPreferencia({ gender:event.target.name, value: event.target.checked });
  };

async function saveProfile(){
    uiStore.setLoading(true);
    await store.saveProfile()
    uiStore.setLoading(false);
    uiStore.setAlertProps({'message':'Perfil salvo com sucesso', 'severity':'success'});
    uiStore.setAlertOpen(true);
    console.log(idEvento);
    if(idEvento){
        history.push('/game?hash='+idEvento);
    }    
}
return(
    <Header>
        <Container component="main" maxWidth="xs">
            {idEvento && 
            <Alert severity="info">Para entrar no jogo, favor completar seu perfil!</Alert>
            }
<Grid container spacing={3}>
        <Grid item xs={12}>
              {/* <ImageselectorComponent></ImageselectorComponent> */}
        <SingleImageSelectorComponent></SingleImageSelectorComponent>
        </Grid>

        <Grid item xs={12}>
            <TextField
                id="outlined-multiline-static"
                label="Nome"
                fullWidth
                inputProps={{ maxLength: 50 }}
                defaultValue=""
                variant="outlined"
                value={store.name}
                onChange={(e)=>store.setName(e.target.value)}/>
        </Grid>
        <Grid item xs={12}>
            
            <TextField
                id="outlined-multiline-static"
                label="Celular"
                fullWidth
                inputProps={{ maxLength: 50 }}
                defaultValue=""
                variant="outlined"
                InputProps={{
                    inputComponent: CelMask as any,
                  }}
                value={store.celular}
                onChange={(e)=>store.setCelular(e.target.value)}/>
        </Grid>



        {/* <Grid item xs={12}>
            <TextField
                id="outlined-multiline-static"
                label="Descrição"
                multiline
                rows={4}
                fullWidth
                defaultValue=""
                variant="outlined"
                value={store.descricao}
                onChange={(e)=>store.setDescricao(e.target.value)}/>
        </Grid> */}
        <Grid item xs={12}>
            <FormControl component="fieldset">
                <FormLabel component="legend">Gênero</FormLabel>
                <RadioGroup 
                  value={store.genero}
                  onChange={(e)=>store.setGenero(e.target.value)}
                  name="genero">
                    <FormControlLabel value="female" control={<Radio color='primary' />} label="Feminino" />
                    <FormControlLabel value="male" control={<Radio color='primary' />} label="Masculino" />
                    <FormControlLabel value="other" control={<Radio color='primary' />} label="Outro" />
                </RadioGroup>
            </FormControl>
        </Grid>
        <Grid item xs={12}>
            <FormControl component="fieldset" >
            <FormLabel component="legend">Tenho interesse em:</FormLabel>
            <FormGroup>
            <FormControlLabel
                control={<Checkbox icon={<FavoriteBorder />} checked={store.preferencia.male} color='primary' name="male" onChange={handleChange} />}
                label="Masculino"
            />
            <FormControlLabel
                control={<Checkbox  icon={<FavoriteBorder />} checked={store.preferencia.female} color='primary' name="female" onChange={handleChange}/>}
                label="Feminino"
            />
            <FormControlLabel
                control={<Checkbox icon={<FavoriteBorder />} checked={store.preferencia.other} color='primary' name="other" onChange={handleChange}/>}
                label="Outro"
            />
            </FormGroup>
        </FormControl>
    </Grid>
    <GeneralAlert></GeneralAlert>

    <Grid item xs={12}>
        <Button variant="contained" fullWidth color="primary" onClick={saveProfile}>Salvar</Button>
    </Grid>
    </Grid>

</Container>
</Header>

)});