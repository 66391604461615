import { observer } from 'mobx-react';
import React, { createContext, useContext } from 'react';
import MyProfileStore from '../../../../store/MyProfileStore';
import './singleprofileimagecard.css';
import profile_placeholder from '../../../../static/profile_placeholder.png'; // Tell webpack this JS file uses this image
import { Box } from '@material-ui/core';



export const SingleProfileImageCard: React.FC= observer(() => {
  const store = useContext(createContext(MyProfileStore));
  const divStyles ={
    backgroundImage:'url("'+store.images[0]+'")', 
    width:'200px', 
    height:'355px',
    backgroundSize: 'cover'
  };
  const noimgStyle ={
    backgroundImage:'url("'+profile_placeholder+'")',
    width:'200px', 
    height:'355px',
    backgroundSize: 'cover' 
  }

  return(
    <Box display='flex' justifyContent='center' flexDirection='row' >
    {store.images[0] &&
      <React.Fragment>
      <Box display='flex' justifyContent='flex-end' flexDirection='row' style={divStyles} >
      </Box>
      </React.Fragment>
    }
     {!store.images[0] &&
      <React.Fragment>
        <Box display='flex' justifyContent='flex-end' flexDirection='row' style={noimgStyle} >
        </Box>
      </React.Fragment>
    }
    </Box>
  )
});