import moment from "moment";


const UtilService = {
    formatTime(property: string, rowdata: any){
        let retorno =' - ';
        if(rowdata && rowdata[property]){
          let date = moment(rowdata[property]);
          retorno = date.utc().format('DD/MM/YY hh:mm');
        }
        return retorno;
      },      
      formatTimeFromString(dateStr: string){
        let retorno =' - ';
        let date = moment(dateStr);
        retorno = date.utc().format('DD/MM/YY hh:mm');
        return retorno;
      },
      log(...messages: any[]){
        if(process.env.NODE_ENV === 'development'){
            console.log(messages);
        }
      }      
}
export default UtilService;