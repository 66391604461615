import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import React,{useContext,createContext } from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import ChatStore from '../../../store/ChatStore';
import chat_tile from '../../../static/chat_tile.png'; // with import




// const Container = styled.div`
//   display: flex;
//   height: 50px;
//   padding: 5px;
//   width: calc(100% - 10px);
// `;

// const ActualInput = styled.input`
//   width: calc(100% - 50px);
//   border: none;
//   border-radius: 999px;
//   padding: 10px;
//   padding-left: 20px;
//   padding-right: 20px;
//   font-size: 15px;
//   outline: none;
//   box-shadow: 0 1px silver;
//   font-size: 18px;
//   line-height: 45px;
// `;

// const SendButton = styled(Button)`
//   min-width: 50px !important;
//   width: 50px !important;
//   border-radius: 999px !important;
//   background-color: var(--primary-bg) !important;
//   margin: 0 5px !important;
//   margin-right: 0 !important;
//   color: white !important;
//   padding-left: 20px !important;
//   svg {
//     margin-left: -3px;
//   }
// `;

interface MessageInputProps {}

const MessageInput: React.FC<MessageInputProps> = observer(() => {
const chatStore = useContext(createContext(ChatStore));
    const useStyles = makeStyles({
        footer: {
        height: "100px", 
        width:"100%",
        backgroundImage : `url(${chat_tile})`

    },
        container :{ 
            display: 'flex',
            height: '50px',
            padding: '5px',
            width: '100%'
        },
        actualInput:{
            width: "calc(100% - 50px)",
            border: "none",
            borderRadius: "999px",
            padding: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
            fontSize: "15px",
            outline: "none",
            boxShadow: "0 1px silver",
            lineHeight: "45px",
        },
    sendButton: {
        minWidth: "50px !important",
        width: "50px !important",
        borderRadius: "999px !important",
        backgroundColor: "white !important",
        margin: "0 5px !important",
        marginRight: "0 !important",
        color: "#e67a7a",
        paddingLeft: " 20px !important",
        svg :{
        marginLeft: " -3px",
         }
    }
        });




  const [message, setMessage] = useState('');

  const onKeyPress = (e: any) => {
    if (e.charCode === 13) {
      submitMessage();
    }
  };

  const onChange = ({ target }: any) => {
    setMessage(target.value);
  };

  const submitMessage = () => {
    if (!message) return;
      chatStore.sendMessage(message);
      setMessage('');
  };
  const classes = useStyles();

  return (
    <div className={classes.footer}> 
<div className={classes.container}>
      <input className={classes.actualInput}
        data-testid="message-input"
        type="text"
        placeholder="Digite uma mensagem..."
        value={message}
        onKeyPress={onKeyPress}
        onChange={onChange}
        multiple
      />
      <Button className={classes.sendButton}
        data-testid="send-button"
        variant="contained"
        color="primary"
        onClick={submitMessage}>
        <SendIcon />
      </Button>
    </div>
    </div>
  );
});

export default MessageInput;