import {  Grid } from '@material-ui/core';
import React from 'react';
import './gamecard.css';
import { LikebtnComponent } from '../../../layout/likebtn/likebtnComponent';
import profile_placeholder from '../../../static/profile_placeholder.png'; // Tell webpack this JS file uses this image
import { observer } from 'mobx-react';

interface GameCardProps{
  profile?: any,
  eventId:any
}

export const GamecardComponent: React.FC<GameCardProps>= observer((props: GameCardProps) => {

  function getImage(){
    if(props.profile.pictures){
      return JSON.parse(props.profile.pictures)[0];
    }else{
      return profile_placeholder;
    }
  }
  return(
    <Grid  item xs={6} sm={2} > 
      <div className='cardWrapper' style={{marginTop:'10px', backgroundImage: `url(${getImage()})` , backgroundRepeat: 'no-repeat', backgroundSize: 'contain' , backgroundPosition: 'center', backgroundColor:'#e6e6e6'}}>
        <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", height:"100%",width:"100%"}}>
        <div className='halfContainer'> 
        {props.profile?.name && 
         <div className='name'>
            {props.profile?.name.substring(0, 20)}  
          </div>
        }
        </div>
        <div className='halfContainer btnContainer'>
          <div>
          <LikebtnComponent targetId={props.profile.id} eventId={props.eventId}></LikebtnComponent>
          </div>
          </div>
      </div>
      </div>
  </Grid>
  )
});