import { Box, Button } from '@material-ui/core';
import React, { createContext, useContext } from 'react';
import './singleimageselector.css';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { useHistory } from 'react-router-dom';
import MyProfileStore from '../../../store/MyProfileStore';
import { observer } from 'mobx-react';
import { SingleProfileImageCard } from './singleprofileimagecard/singleprofileimagecardComponent';



export const SingleImageSelectorComponent: React.FC= observer(() => {
  const store = useContext(createContext(MyProfileStore));
  const history = useHistory();
  function imageSelected(event: any){
    var file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      store.setSelectedImage(reader.result);
      history.push('/profileimagecropper')
    };
  }
  return(
    <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center'	 >
     <SingleProfileImageCard></SingleProfileImageCard>
     <Box mt={5}>
     <React.Fragment>
      <input
      accept="image/*"
      id="contained-button-file"
      multiple={false}
      hidden={true}
      type="file"
      onChange={e=>{imageSelected(e)}}
      />
       <label htmlFor="contained-button-file">
       <Button variant="contained" color="primary" component="span" startIcon={<PhotoCameraIcon></PhotoCameraIcon>}>
        Adicionar imagem
      </Button>
      </label>
      </React.Fragment>
      </Box>
  </Box>
  )
})