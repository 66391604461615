import { makeStyles } from "@material-ui/core";

export const facebookloginbuttonStyles = makeStyles((theme) => ({
    facebook: {
        display: 'flex',
        background: '#4267B2',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(105, 172, 255, 0)',
        color: 'white',
        height: 48,
        padding: '0 30px',
        '&:hover': {
          background: "#4267B2",
       }
    },
    button: {
        background: 'transparent',
        border: 'none !important',
        fontSize:0
    }
    }));