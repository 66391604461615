import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { pink } from '@material-ui/core/colors';

interface MessageItemProps {
    id: string | null;
    content: string | null;
    createdAt: string | null;
    isMine: boolean
  }


export const MessageItem: React.FC<MessageItemProps> = (props: MessageItemProps) => {

    const useStyles = makeStyles({
        messageItem:{
            display: "inline-block",
            position: "relative",
            maxWidth: "100%",
            borderRadius: "7px",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.15)",
            marginTop: "10px",
            marginBottom: "10px",
            minWidth:"6em",
            clear:"both",
            '&::after': {
                content: '',
                display: 'table',
                clear:"both"
            },
            "&::before": {
              content: '',
            position: "absolute",
              bottom: "3px",
              width: "12px",
              height: "19px",
            backgroundPosition:"50% 50%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain"    
            },
        },
            isMine:{
                float : "right",
                backgroundColor : pink[200], //TODO change
                "&::before": {
                  right : "-11px",
                  backgroundImage : "url(/assets/message-mine.png)"
                }
            },
            isOther:{
                float : "left",
                backgroundColor : "#fff",
                "&::before": {
                    left: "-11px",
                  backgroundImage : "url(/assets/message-other.png)" 
                }
            },
           contents:{ 
            padding: "5px 7px",
            wordWrap: "break-word",
            '&::after': {
                display: "inline"
                }
            },
        timestamp :{
            position: "absolute",
            bottom: "2px",
            right: "7px",
            color: "gray",
            fontSize: "12px"
        }
        });

    const classes = useStyles();
    //iMine={props.isMine}
    return (
            <div className={`${classes.messageItem} ${(props.isMine? classes.isMine:classes.isOther)}`} data-testid="message-item" >
              <div className={classes.contents} data-testid="message-content">{props.content}</div>
              <br></br>
               <div className={classes.timestamp} data-testid="message-date">
                {moment(props.createdAt).format('DD/MM HH:mm')}
              </div>
            </div>
      );
}
