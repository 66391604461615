import { observable, action } from "mobx";
import UtilService from '../services/UtilsService';
import { v4 as uuidv4 } from 'uuid';
const {  xml } = require("@xmpp/client");

class ChatStore {
   uiStore: any;

    @observable 
    chatInfo: any;
    @action setChatInfo = (chatInfo: any) => {
        this.chatInfo= chatInfo;
     }
   


  // @observable 
  // chatName = "";
  // @action setChatName = (chatName: string) => {
  //     this.chatName= chatName;
  //  }
  //  @observable 
  //  chatImg = "";
  //  @action setChatImg = (chatImg: string) => {
  //      this.chatImg= chatImg;
  //   }
  //   @observable 
  //   chatId = "";
  //   @action setChatId = (chatId: string) => {
  //       this.chatId= chatId;
  //    }

  @observable 
    status = "OFF";
  @action setStatus = (status: string) => {
      this.status= status;
   }

    @observable 
    myAddress: string = "";
    @action setMyAddress = (address: string) => {
      this.myAddress= this.getJid(address);
   }


    @observable
    xmpp : any;

  @observable  
    chats:any={};
  
  @observable  
    chats_archive:any={};



  @action addMessage = (message: any) => {
    if(message.attrs.type !== 'chat'){
      this.storeArchive(message);
    }else{
      this.msgrealTime(message);
    }
   
    }
    private msgrealTime(message: any){
      this.processMessage(message, this.chats)
    }
    private processMessage(stanza: any, arr: any, createdAt?: any){
      let {chatName, message} = this.getMessageFromStanza(stanza)
      if(!arr[chatName]){
         arr[chatName]=[];
      }
        const found = arr[chatName].some((el : any) => el.id === message.id);
        if(createdAt){
          message.createdAt = createdAt;
        }
        if(!found){
          arr[chatName].push(message); 
        }
    }

    private getMessageFromStanza(stanza: any){

      let from = this.getJid(stanza?.attrs?.from);
      let to = this.getJid(stanza?.attrs?.to);
      let chatName = to;
      let msg = {from:from,     id: '',
      content: '', isMine: true, createdAt: new Date(), to: to}
      if(to === this.myAddress){
        msg.isMine = false;
        chatName = from;
      }
      for (const el in stanza.children) {
          if(stanza.children[el].name === 'body'){
            msg.content= stanza.children[el].children[0];
          }
          if(stanza.children[el].name === 'archived'){
            msg.id= stanza.children[el].attrs.id;
          }
        }
        return {chatName:chatName, message:msg}
    }
    private getDelayAndMessageFromStanza=(stanza: any[])=>{
      //TODO via loop
      let delay;
      let messageStanza;
      stanza.forEach(children => {
        if(children.name === 'delay'){
          delay = children.attrs.stamp;
        }
        if(children.name === 'message'){
          messageStanza= children;
        }
      });
      
      return {delay: delay, messageStanza: messageStanza};
    }
    @action sendMessage = (message: any) => {
      const to =this.chatInfo.chatId;
      const msg = xml(
        "message",
        { type: "chat", to:  to},
        xml("body", {}, message),
      );
      this.xmpp.send(msg);
      if(!this.chats[to]){
        this.chats[to]=[];
      }
  
      let localMsg = {from:this.myAddress,     id: uuidv4(),
        content: message, isMine: true, createdAt: new Date()}
      this.chats[to].push(localMsg); 
    }

    private getJid(fullAdress: string): string{
      return fullAdress.split( '/' )[0];
    }
    private storeArchive(stanza: any){
      let{delay, messageStanza} = this.getDelayAndMessageFromStanza(stanza.children[0].children[0].children)
      this.processMessage(messageStanza, this.chats_archive, delay);
    }
    @action popArchive(stanza: any){
      console.log('pop Archive:', stanza.children[0].attrs.queryid);
      const chatName= stanza.children[0].attrs.queryid;
      console.log('ChatName', chatName);
      if(chatName){
      if(!this.chats[chatName]){
        this.chats[chatName]=[];
     }
      this.chats[chatName]= [...this.chats[chatName], ...this.chats_archive[chatName]]
    }
    }
   @action setXmpp = (xmpp: any) => {
     UtilService.log('Instanciando o xmpp no store');
    this.xmpp= xmpp;
 }
  @action getHistory =()  => {
    console.log('getHistory', this.chatInfo);
    if(this.xmpp){
    const query = xml("iq",{type:'set', id:"97605218-2e82-453e-bdd2-b40b936f4dbb"},
                         xml("query",{xmlns:'urn:xmpp:mam:2' ,queryid:this.chatInfo.chatId},
                          xml("x",{ xmlns:'jabber:x:data', type:'submit'},
                            xml("field",{ var:'FORM_TYPE', type:'hidden'},
                              xml("value",{},'urn:xmpp:mam:2')),
                         xml("field",{ var:'with'},
                            xml("value",{},this.chatInfo.chatId)),
                         )
                         ));
    this.xmpp.send(query);
  }
  }
  
}


export default new ChatStore();