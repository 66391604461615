import axios from 'axios';
import UtilService from './UtilsService';

const CheckinService = {
  async create(code: any): Promise<any> {
    UtilService.log(`Checkin for qrcode ${process.env.REACT_APP_ENDPOINT}/checkin/${code}`,{headers:{UserMetrics:window.localStorage.getItem("UserMetrics")},withCredentials: true});
    try {
      const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}/checkin/${code}`,{}, {headers:{UserMetrics:window.localStorage.getItem("UserMetrics")},withCredentials: true});
      return response.data;
  } catch(error) {
    UtilService.log(error);
      return error.response;
  }
},
async delete(id: number): Promise<any> {
  try {
    const response = await axios.delete(process.env.REACT_APP_ENDPOINT+'/checkin/'+id);
    return response.data;
} catch(error) {
    return error.response;
}
},
async getProfilesInGame(idEvento: any,offset: any, mygender: any, interest: any): Promise<any> {
  try {
    let intes: any[] =  [];
    for (var prop in interest) {
      if (Object.prototype.hasOwnProperty.call(interest, prop)) {
         UtilService.log(interest[prop]);
          if(interest[prop]){
            intes.push(prop);
         } 
      }
    }
    UtilService.log(`Interest ${intes}, offset ${offset}`);
    const url = `${process.env.REACT_APP_ENDPOINT}/ingame?evento=${idEvento}&offset=${offset}&mygender=${mygender}&interest=${intes}`;
    UtilService.log(`URL: ${url}`);
    const response = await axios.get(url,{headers:{UserMetrics:window.localStorage.getItem("UserMetrics")},withCredentials: true} );
    UtilService.log('Response',response);
    return response.data;
  } catch(error) {
      return error.response;
  }
},


};

export default CheckinService;