import React, { useContext, createContext, useEffect } from 'react'
import Container from '@material-ui/core/Container';
import { useStyles } from './styles';
import UIStore from '../../store/UIStore';
import { observer } from 'mobx-react';
import Header from '../../layout/header/Header';
import { Typography, Avatar, CssBaseline } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

 
export const ErrorPage: React.FC = observer(() => {
const styles = useStyles();
const uistore = useContext(createContext(UIStore));

useEffect(() => {
    uistore.setLoading(false);
}, [uistore]);
return(
    <Header>
        <Container component="main" maxWidth="xs">

<CssBaseline />
<div className={styles.paper}>
        <Avatar className={styles.avatar}>
        <WarningIcon style={{ fontSize: 50 }}/>
        </Avatar>

      <Typography component="h1" variant="h5">
        {uistore.errorMsg}
      </Typography>
      <Typography variant="subtitle1" align='center'>

      </Typography>
      </div>
</Container>
</Header>
)})