import React from 'react'
import {  Box, List,    Paper } from '@material-ui/core';
import { ChatItem } from './chatitem/chatitemComponent';

import BackHeader from '../../layout/header/BackHeader';
import useFicadas from '../../hooks/useFicadas';
import UtilService from '../../services/UtilsService';

export const Chat: React.FC = () => {

const { data, status } = useFicadas();
const Ficadas = (data: any) => {
    UtilService.log(data);
    return data.map((ficada: any) => {
     return <ChatItem userImage={JSON.parse(ficada.pictures)[0]} name={ficada.name} userId={0} chatId={ficada.chatId} endDate={ficada.endDate}></ChatItem>
    });
   };
return(
    <BackHeader>
  
        <Box component={Paper} mt={1}>
        {status === "loading" && <div>Carregando...</div>}
        {status === "error" && <div>Error fetching profile</div>}
        {status === "success" && <List>{Ficadas(data)}</List>}
        </Box>
    </BackHeader>
)}