import axios from 'axios';

const LikeService = {
  async create(like: any): Promise<any> {
    try {
      const response = await axios.post(process.env.REACT_APP_ENDPOINT+'/like', like);
      return response.data;
  } catch(error) {
      return error.response;
  }
}



};

export default LikeService;