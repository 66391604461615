import { createMuiTheme } from '@material-ui/core/styles';

declare module "@material-ui/core/styles/createPalette" {
    interface Palette {
      danger: Palette['primary'];
    }
    interface PaletteOptions {
      danger: PaletteOptions['primary'];
    }

}
export default function createGoldTheme() : any{
    return createMuiTheme({
        palette: {
            type: 'light',
            danger: {
              main: '#e53e3e',
            },         
            primary: {
                // light: será calculada com base em palette.primary.main,
                main: '#e67a7a',
                // dark: será calculada com base em palette.primary.main,
                contrastText: '#FFFFFF'
              },
              success: {
                // light: será calculada com base em palette.primary.main,
                main: '#4BB543',
                // dark: será calculada com base em palette.primary.main,
                contrastText: '#FFFFFF'
              },
              // secondary: {
              //   light: '#0066ff',
              //   main: '#0044ff',
              //   // dark: será calculada com base palette.secondary.main,
              //   contrastText: '#ffcc00',
              // },
              // Usado por `getContrastText()` para maximizar o contraste entre
              // o plano de fundo e o texto.
              contrastThreshold: 3,
              // Usado pelas funções abaixo para mudança de uma cor de luminância por aproximadamente
              // dois índices dentro de sua paleta tonal.
              // Por exemplo, mude de Red 500 para Red 300 ou Red 700.
              tonalOffset: 0.2,
            }
        })
}