import { observable, action } from "mobx";
import { createContext } from "react";
import LikeService from '../services/LikeService';
class FicadaStore {
   uiStore: any;

  @observable 
  detailImage: string = "";
 
  @observable
  liked_profiles : any[] =[]
  @action setDetailImage = (url: string) => {
    this.detailImage= url;
 }

 @action likeProfile = (idUser: string, idTarget: string, eventId: string) => {
   this.liked_profiles.push({idTarget:idTarget,eventId:eventId});
   LikeService.create({ idUser: idUser, idTarget: idTarget , eventId: eventId })
}

}


export default createContext(new FicadaStore())