import { useQuery } from 'react-query'
import AuthService from "../services/AuthService";
import UtilService from '../services/UtilsService';
import MyProfileStore from '../store/MyProfileStore';
import {client, xml} from "@xmpp/client";
import ChatStore from '../store/ChatStore';
 

    const getMe = async() =>{
      
       const user_info = await AuthService.me();
       const store =  MyProfileStore;
       const chatStore = ChatStore;
      //  UtilService.log('Load me from hook', user_info);
     console.log('Load me from hook', user_info);

       store.loadFromMe(user_info); 
       if(user_info.chatId){
        UtilService.log('User has chat', user_info);
        const xmpp = client({
            service: 'wss://chat.ficada.com.br:5443/ws',
            domain: "chat.ficada.com.br",
            username: "97605218-2e82-453e-bdd2-b40b936f4dbb",
            password: "N4HsgZ6OlwoWTJldCZXtNPhyLrXDEGW1wzG",
          });
          xmpp.on("error", (err) => {
            console.error(err);
          });
          
          xmpp.on("offline", () => {
            console.log("offline");
            chatStore.setStatus("OFF");
          });
          
          xmpp.on("stanza", async (stanza) => {
            // console.log('stanza', stanza);

            if (stanza.is("message")) {
                // console.table(stanza.getChild("body").text())
                chatStore.addMessage(stanza);
            }else if(stanza.attrs.type === 'result'){
              chatStore.popArchive(stanza);
            }
          });
          
          xmpp.on("online", async (address) => {
            // Makes itself available
           await xmpp.send(xml("presence"));
           chatStore.setStatus("ON");
           chatStore.setMyAddress(address.toString())
           chatStore.setXmpp(xmpp);
          //   const query =(<iq type='set' id='chat.ficada.com.br'>
          //   <query xmlns='urn:xmpp:mam:2'>
          //     <x xmlns='jabber:x:data' type='submit'>
          //       <field var='FORM_TYPE' type='hidden'>
          //         <value>urn:xmpp:mam:2</value>
          //       </field>
          //       <field var='with'>
          //         <value>admin@chat.ficada.com.br</value>
          //       </field>
          //     </x>
          //   </query>
          // </iq>);
         
          // const message = xml(
          //   "iq",
          //   { type: 'set', id:'chat.ficada.com.br' },
          //     xml("query", 'urn:xmpp:mam:2',
          //       xml("x",{xmlns:'jabber:x:data', type:'submit'},
          //       xml("field", {var:"FORM_TYPE", type:'hidden'}))
             
          //   ),
          // );
         
            // const query = xml("iq",{type:'set', id:"97605218-2e82-453e-bdd2-b40b936f4dbb"}, xml("query",{xmlns:'urn:xmpp:mam:2' ,queryid:'f27'}))


          // console.log('query', query);
          //  await xmpp.send(query);


           
          console.log('online');
            // Sends a chat message to itself
        //     const message = xml(
        //       "message",
        //       { type: "chat", to: address },
        //       xml("body", {}, "hello world"),
        //     );
        //     await xmpp.send(message);
          });
          
          xmpp.start().catch(console.error);
       }
       return user_info;
       





    }
    export default function useMe() {
        return useQuery('me', getMe);
    }