import MyProfileService from './MyProfileService';
import UtilService from './UtilsService';

const ImageCropperService = {
  async createImage (url:any) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => {
      resolve(image)
    })
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })},

async getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180
},
/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
 async  getCroppedImg(imageSrc: any, pixelCrop: any, rotation = 0) {
  const image : HTMLImageElement= await this.createImage(imageSrc)  ;
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  UtilService.log('Imagem',image);
  const maxSize = Math.max(image?.width, image?.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea
  canvas.height = safeArea

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx?.translate(safeArea / 2, safeArea / 2)
  ctx?.rotate(await this.getRadianAngle(rotation))
  ctx?.translate(-safeArea / 2, -safeArea / 2)

  // draw rotated image and store data.
  ctx?.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  )
  const data = ctx?.getImageData(0, 0, safeArea, safeArea)

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image with correct offsets for x,y crop values.
  if(data){
  ctx?.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  )
}
  // As Base64 string
  // return canvas.toDataURL('image/jpeg');
  const base64Img =canvas.toDataURL('image/jpeg', 1.0);
  const imageUrl = MyProfileService.uploadImage(base64Img);
 return imageUrl;
  // As a blob
  // return new Promise(resolve => {
  //   canvas.toBlob(file => {
  //     resolve(URL.createObjectURL(file))
  //   }, 'image/jpeg')
  // })
},
getImageResized(image: HTMLImageElement): HTMLImageElement{
  const canvas = document.createElement('canvas')
  let ctx = canvas.getContext("2d");
  const MAX_WIDTH = 200;
  const MAX_HEIGHT = 355;
  let width = image.width;
  let height = image.height;
  UtilService.log('image', image);
if (width > height) {
  if (width > MAX_WIDTH) {
    height *= MAX_WIDTH / width;
    width = MAX_WIDTH;
  }
} else {
  if (height > MAX_HEIGHT) {
    width *= MAX_HEIGHT / height;
    height = MAX_HEIGHT;
  }
}
canvas.width = width;
canvas.height = height;
ctx?.drawImage(image, 0, 0, width, height);
var imageResponse =  document.createElement("img") as HTMLImageElement;
imageResponse.src =  canvas.toDataURL("image/jpeg");
UtilService.log(imageResponse);
return imageResponse;
}
};
export default ImageCropperService;