 import React from 'react';
import { FacebookLoginButtonComponent } from './facebookloginbutton/facebookloginbuttonComponent';
import './logincard.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {  LogoImgComponent } from '../logoimg/logoimgComponent';


export const LoginCardComponent: React.FC= () => {
  
  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://ficada.com.br/">
        Ficada 

            </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  
  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));
  
    const classes = useStyles();
  
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <LogoImgComponent></LogoImgComponent>
          <Box mt={3}>
          <Typography variant="subtitle1" gutterBottom>
            Bem vindo ao Ficada,<br></br> 
            
            Ao realizar o login você concorda com os 
            <Link href="https://ficada.com.br/termos-e-condicoes-de-uso/" target='_blank'>
            Termos e Condições de uso do site
            </Link>, 
            e também com a nossa<Link href="https://ficada.com.br/politica-de-privacidade/" target='_blank'>
            Política de privacidade
            </Link>
            
          </Typography>
          </Box>
          <Box mt={3}>
          <Typography variant="subtitle2" gutterBottom>
            Lembrando que no fim do Evento seus likes e Ficadas desaparecem!
          </Typography>
          </Box>
          <Box mt={2}>
              <FacebookLoginButtonComponent></FacebookLoginButtonComponent>
          </Box>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }





