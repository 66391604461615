import React from 'react'
import Container from '@material-ui/core/Container';
import { observer } from 'mobx-react';
import Header from '../../layout/header/Header';
import { Typography } from '@material-ui/core';
import { LoginCardComponent } from '../../layout/logincard/logincardComponent';
import useMe from '../../hooks/useMe';

export const Home: React.FC = observer(() => {
const me = useMe();
return(
      <Header>
            <Container component="main" >
            {!me.data?.id &&
             <LoginCardComponent></LoginCardComponent>
            }
                  {me.data?.id &&
    <React.Fragment>
     <div id='greeting'>
     <Typography  color="textSecondary"  gutterBottom>
       Entrar em um novo jogo
      </Typography>
      <Typography variant="body2" component="p" >
       Escaneie o código QR Code dos lugares pra paquerar com outros 
       frequentadores daquele local
      </Typography>
      </div>
      <br/>
  </React.Fragment>
   }
            </Container>
      </Header>
)});