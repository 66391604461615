import { observable, action, computed } from "mobx";
import AuthService from "../services/AuthService";
class SmsStore {
   uiStore: any;
   @observable 
   phone_number:string ='';
   @observable 
   wasPhone_numberEdited:boolean = false;
   @action setPhone_number= (phone_number: string) => {
    this.wasPhone_numberEdited = true;
    this.phone_number= phone_number;
  }

   @computed get isPhone_numberValid() {
     if(this.phone_number.length<10 && this.wasPhone_numberEdited){
       return {isValid:false, helper:"Telefone Inválido"};
     }else{
       return {isValid:true, helper:""};;
     }
 }
 @action async login(qrcode: string){
    return await AuthService.qrcodeLogin(qrcode,this.phone_number);
 }
 @action async confirm(otp: string){
  return await AuthService.confirm(otp);
}

}


export default new SmsStore();