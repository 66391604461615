import React, { createContext, useContext } from 'react'
import Container from '@material-ui/core/Container';
import Header from '../../layout/header/Header';
import { LinearProgress, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import CheckinService from '../../services/CheckinService';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { LoginCardComponent } from '../../layout/logincard/logincardComponent';
import useMe from '../../hooks/useMe';
import UtilService from '../../services/UtilsService';
import UIStore from '../../store/UIStore';


export const Checkin: React.FC = observer(() => {
    const query =new URLSearchParams(useLocation().search);
    const uistore = useContext(createContext(UIStore));
    const qrcode = query.get("qrcode");
    const history = useHistory();
    const me = useMe();
    useEffect(() => {
        if(me.data?.id){
            checkin();
        } 
    });

    async function checkin(){
        UtilService.log(qrcode);
        const check = await CheckinService.create(qrcode);
        UtilService.log('CHECK',check);
        //TODO tratar erro no checkin
        if(check?.evento?.id){
            history.push(`/game?hash=${check.evento.id}`);
        }else{
            uistore.setErrorMsg('Check In não pode ser realizado');
            history.push(`/error`);
        }
    }
return(
    <Header>
        <Container  component="main" maxWidth="xs">
                {!me.data?.id && 
                    <LoginCardComponent></LoginCardComponent>
                }
                {me.data?.id && 
                <div>
                <Typography variant="body1" gutterBottom>
                    Um momento enquanto realizamos o checkin
                 </Typography>
                <LinearProgress color="primary" />
                </div>
                }
            </Container>
    </Header>
)})