import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import React, { createContext, useContext } from 'react';
import './chatitem.css';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import ChatStore from '../../../store/ChatStore';
import Countdown from 'react-countdown';

interface ChatitemProps{
  userImage?: string,
  name?:string,
  userId?:number,
  chatId?:string,
  endDate?:Date
}

export const ChatItem: React.FC<ChatitemProps>= observer((props: ChatitemProps) => {
  const history = useHistory();
  const chatStore = useContext(createContext(ChatStore));

  function gotoDetails(){
    // if(props.userImage){
    //   ficadaStore.setDetailImage(props.userImage);
    // }
   chatStore.setChatInfo({
    userImage: props?.userImage,
    name:props?.name,
    userId:props?.userId,
    chatId:props?.chatId
   })
   
    history.push('/message')
  }
  return(
    <ListItem alignItems="flex-start" button  onClick={gotoDetails}>
    <ListItemAvatar>
      <Avatar src={props.userImage} />
    </ListItemAvatar>
    <ListItemText
      primary={props.name}
      secondary={
        <React.Fragment>
          <Typography
            component="span"
            variant="caption"
            color="textSecondary"
          >
            {props.endDate && 
             <>Desaparece em: <b><Countdown date={props.endDate} /></b></>
            }  
          </Typography>
        </React.Fragment>
      }
    />
    
  </ListItem>
  )
});