import { makeStyles } from "@material-ui/core";

export const useCropperStyles = makeStyles((theme) => ({
    cropcontainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: '80px'
      },
      controls:{
         position: 'absolute',
            bottom: 0,
            left: '50%',
            width: '50%',
            transform: 'translateX(-50%)',
            height: '80px',
            display: 'flex',
         alignItems: 'center'
          }
    }));