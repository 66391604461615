import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {  Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// const drawerWidth = 240;


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection:'column'
        },
       menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
   
   
    // content: {
    //   flexGrow: 1,
    //   padding: theme.spacing(5),
    //   transition: theme.transitions.create('margin', {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.leavingScreen,
    //   }),
    //   marginLeft: -drawerWidth,
    // },
    contentShift: {
      flexGrow: 1,
      marginLeft: 0,
    }
  }),
);

const Header = observer((props: { children: React.ReactNode; }) => {
  const classes = useStyles();
  const history = useHistory();
  
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="sticky"
        style={{ background: 'black' }}
      >
        <Toolbar>
        <Grid item>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={()=>{history.goBack()}}
            edge="start"
            data-uandu='open-menu-button'
          >
            <ArrowBackIosIcon />
          </IconButton>
          </Grid>
 
          <Grid item xs />        
        </Toolbar>
      </AppBar>
        {props.children}
    </div>
  
  );
})
export default Header; 