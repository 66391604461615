import React from 'react';
//Import Pages
import ReactDOM from 'react-dom';
import './index.css';
import 'typeface-roboto';
import * as serviceWorker from './serviceWorker';
import 'typeface-roboto';
import { ThemeProvider } from '@material-ui/core';
import BaseRouter from './routers/base.router';
import createGoldTheme from './theme/createGoldTheme';
import {
  QueryClient,
  QueryClientProvider,
} from "react-query";

const queryClient = new QueryClient();

const theme = createGoldTheme();

ReactDOM.render(
<React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      {/* <BackdropGlobalComponent></BackdropGlobalComponent> */}
      {/* <ReactQueryDevtools initialIsOpen={true} /> */}
    <BaseRouter></BaseRouter>
    </ThemeProvider>
  </QueryClientProvider>
</React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
