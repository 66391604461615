import React, { createContext, useContext, useEffect, useState } from 'react'
import Container from '@material-ui/core/Container';
import Header from '../../layout/header/Header';
import { useParams, useHistory } from 'react-router-dom';
import UIStore from '../../store/UIStore';
import { observer } from 'mobx-react';
import SmsStore from '../../store/SmsStore';
import { Typography } from '@material-ui/core';
import UtilService from '../../services/UtilsService';


export const Confirm: React.FC = observer(() => {
const uiStore = useContext(createContext(UIStore));
const smsStore = useContext(createContext(SmsStore));
const {otp} =useParams();
const [error, setError] = useState(false);
const history = useHistory();


useEffect(() =>{
    uiStore.setLoading(true);
    async function fetchMyAPI() {
        let response = await smsStore.confirm(otp);
        UtilService.log('Backend terminou',response);
        if(response.status !==200){
            setError(true);
        }else{
            history.push('/game?hash='+response.data.evento.id);
        }
        uiStore.setLoading(false);
    }
      fetchMyAPI()
}, [history, otp, smsStore, uiStore]);
return(
    <Header>
        <Container component="main" maxWidth="xs">
        {error && 
        <Typography color='error' variant="subtitle2" gutterBottom>
          Erro ao tentar realizar o Checkin.
          Por favor, certifique-se que você acessou um link válido no SMS
        </Typography>
    }
        </Container>
    </Header>
)})