import axios from 'axios';
import Cookies from 'js-cookie';
import UtilService from './UtilsService';


const AuthService = {
    async login(email:string, password: string): Promise<any> {
            const response = await axios.post(
                process.env.REACT_APP_ENDPOINT+'/auth/login',
            {email:email, password: password},  {withCredentials: true});
           
            return response.data;
    },
    async logout(): Promise<void> {
        try {
            await axios.post(process.env.REACT_APP_ENDPOINT+'/auth/logout',{}, {headers:{UserMetrics:window.localStorage.getItem("UserMetrics")},withCredentials: true});
            Cookies.remove('cuppido');
            window.localStorage.clear();
        } catch(error) {
            return error.response;
        }
    },
    async me(): Promise<any>{
        const response = await axios.get(process.env.REACT_APP_ENDPOINT+'/auth/me', {headers:{UserMetrics:window.localStorage.getItem("UserMetrics")},withCredentials: true});
        return response.data;
    },
    async signin(email:string, password: string): Promise<any>{
        const response = await axios.post(process.env.REACT_APP_ENDPOINT+'/auth/signin',{'email':email,'password':password});
        return response;
    },
    async verify(code:string): Promise<any>{
        const response = await axios.post(process.env.REACT_APP_ENDPOINT+'/auth/verify?code='+code);
        return response;
    },
    async newPassRequest(email:string): Promise<any>{
        const response = await axios.post(process.env.REACT_APP_ENDPOINT+'/auth/newpassrequest',{email:email});
        return response;
    },
    async newPass(email:string, code: string, password: string): Promise<any>{
        const response = await axios.post(process.env.REACT_APP_ENDPOINT+'/auth/newpass?code='+code,{email:email,code:code,password:password});
        return response;
    },
    async facebookLogin(fpreponse:any): Promise<any>{
        const response = await axios.post(process.env.REACT_APP_ENDPOINT+'/auth/facebook',fpreponse,{withCredentials:true});
        UtilService.log("UserMetrics",response.headers["usermetrics"]);
        window.localStorage.setItem("UserMetrics", response.headers["usermetrics"])
        
        return response;
    },
    async qrcodeLogin(qrcode:string, phone_number:string): Promise<any>{
        UtilService.log("OTP login");
        const otpRequest= {
                            "phone_number":phone_number,
                            "qrcode":qrcode
                            }
        const response = await axios.post(process.env.REACT_APP_ENDPOINT+'/otp',otpRequest,{withCredentials:true}).catch(error =>{
           return error; 
        });
        return response;
    },
    async confirm(otp:string): Promise<any>{
        const otpRequest= {
                            "hash":otp,
                        }
        const response = await axios.post(process.env.REACT_APP_ENDPOINT+'/otp/confirm',otpRequest,{withCredentials:true}).catch(error =>{
           return error; 
        });
        return response;
    }
};

export default AuthService;