import React from 'react'
import Header from '../../layout/header/Header';
import { observer } from 'mobx-react';
import useMe from '../../hooks/useMe';
import { GameListComponent } from './gamelist/gamelistComponent';
import { useHistory, useLocation } from 'react-router-dom';


export const Game: React.FC = observer(() => {
// const store = useContext(createContext(MyProfileStore));
const query =new URLSearchParams(useLocation().search);
const idEvento = query.get("hash");  
const {data , status} = useMe();
const history = useHistory()

function checkIfProfileIsOK(profile: any): boolean{
  console.log(data);
  let has_preference = (data.preference_male === true || data.preference_female === true || data.preference_other ===true);
  if (data.gender && data.pictures.length>0 && has_preference && data.celular){
    return true
  }else{
    history.push('/myprofile?hash='+idEvento)
    return false;
  }
}
return(
    <Header>
      
      {status === 'success' && 
      <>

      {checkIfProfileIsOK(data) &&
        <GameListComponent profile ={data}></GameListComponent>
      }
      </>
      }
</Header>

)});